<template>
  <div style="padding: 15px">
    <span>Link Name</span>
    <el-input v-model="linkName" placeholder="Enter the Link name"></el-input>
    <span>choose the link type</span>
    <el-card
      style="width: 100%; height: 50px; display: flex; align-items: center"
    >
      <br />
      <el-radio-group v-model="link" style="margin-bottom: 20px">
        <el-row>
          <el-radio label="PUBLIC"></el-radio>
          <el-radio label="PRIVATE"></el-radio>
        </el-row>
      </el-radio-group>
    </el-card>
    <el-collapse style="margin: 20px 0">
      <el-collapse-item title="Permissions" name="1">
        <div
          v-for="(value, key) in getOptions(false)"
          :key="key"
          style="margin-bottom: 2px"
        >
          <el-checkbox
            class="checkbox-item"
            v-model="selectedPermissions"
            :checked="Boolean(value)"
            :label="key"
            >{{ key }}</el-checkbox
          >
        </div>
        <!-- <span v-if="currentPermissionSet.TEMPLATES">
          <el-collapse style="margin: 20px 0">
            <el-collapse-item title="Templates" name="1">
              <div
                v-for="(template, templateId) in getOptions(true)"
                :key="templateId"
              >
                <el-collapse-item :title="getTemplateName(templateId)" :name="templateId">
                    <div v-for="key in Object.keys(template)" :key="key" style="margin-bottom: 2px">
                    <el-checkbox
                    class="checkbox-item"
                    :checked="isChecked(templateId, key)"
                    :label="{ templateId: templateId, key: key }"
                    @change="handleChange(templateId, key)"
                    >{{ key }}</el-checkbox>
                </div>  
                </el-collapse-item>
              </div>
            </el-collapse-item>
          </el-collapse>
        </span> -->
      </el-collapse-item>
    </el-collapse>
    <div style="display: flex" class="mt-1">
      <el-button
        type="danger"
        icon="el-icon-link"
        class="copy-btn"
        style="border-radius: 5px;"
        @click="isEditMode ? updateLink() : generateLink()"
        :disabled="!linkName || !link"
      >
        {{ isEditMode ? "Update Link" : "Generate Link" }}
      </el-button>
      <el-button
        @click="RemoveData()"
        style="border-radius: 5px;"
      >Cancel
      </el-button>
     
    </div>
    
    <div v-if="link == 'Public'"></div>
    <div v-if="inputFlag">
      <!-- <div style="display: flex" class="mt-1">
        <el-input
          size="mini"
          v-model="generatedLink"
          ref="privateLinkRef"
          v-on:focus="$event.target.select()"
        ></el-input>
        <el-button
          type="danger"
          icon="el-icon-copy-document"
          class="copy-btn"
          size="mini"
          @click="copyLinkToClipboard('privateLinkRef')"
        ></el-button>
      </div> -->
    </div>
    <div class="mt-1">
      <p style="margin-bottom: 8px; font-style: bold">Existing link</p>
      <div v-for="(link, index) in getIframeUrLinks" :key="index">
        <el-row class="mt-1">
          <el-col :span="10">
            <p>
              {{ getIframeUrLinks[index].linkName }}
              <el-popover
                placement="top-start"
                trigger="hover"
                :content="getIframeUrLinks[index].url_type"
              >
                <i class="el-icon-info" slot="reference"></i>
              </el-popover>
            </p>
          </el-col>
          <el-col :span="5" style="text-align: right">
            <el-button
              type="danger"
              icon="el-icon-link"
              class="copy-btn"
              size="mini"
              @click="generateExistLink(link)"
              style="float: right; margin-left: 10px;border-radius: 5px"
            >
            </el-button>
          </el-col>
          <el-col :span="5" style="text-align: right">
            <el-switch
              v-model="getIframeUrLinks[index].status"
              :active-value="'ACTIVE'"
              :inactive-value="'INACTIVE'"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="statusChanged(link)"
            ></el-switch>
          </el-col>
          <el-col :span="4" style="text-align: right">
            <el-dropdown>
              <span class="el-dropdown-link">
                <i class="el-icon-more" style="transform: rotate(90deg)"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <a class="edit-btn" @click="editLink(index)">
                  <el-dropdown-item>
                    <i class="el-icon-edit"> Edit</i>
                  </el-dropdown-item>
                </a>
                <a type="danger" :underline="false" @click="onDelete(link)">
                  <el-dropdown-item>
                    <i class="el-icon-delete">Delete</i>
                  </el-dropdown-item>
                </a>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import appConfig from "@/config/app";
import { iFramePermissions } from "@/constants/iFramePermissions.js";
import NavigationHelper from "@/mixins/navigationHelper";
export default {
  props: ["routeKey", "type", "templateData"],
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("auth", [
      "getActiveWorkspace",
      "getAuthenticationDetails",
      "getAuthenticatedSSOToken",
      "getUserTypeList",
    ]),
    ...mapGetters("company", [
      "getContactLoginSlug",
      "getUpdateCompany",
      "getCompanyDetails",
    ]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken"]),
    ...mapGetters("iFrame", [
      "getIframeData",
      "getIframeUrLinks",
      "getIframeUrlUpdatedData",
    ]),
    ...mapGetters("entities", ["getEntityDataById", "getAllEntities"]),
    ...mapGetters("companyTemplates", [
      "getSingleCompanyTemplate",
      "getTemplateDataTempVariable",
    ]),
    getLabel() {
      return (userType) => {
        if (userType && userType.role) {
          return userType.role.title + " - " + userType.name;
        } else {
          return userType.name;
        }
      };
    },
  },
  mixins: [NavigationHelper],
  async mounted() {
    this.getTypeChange(this.type);
    this.currentPermissionSet = this.permissionSet[this.type];
    if (this.currentPermissionSet?.TEMPLATES) {
      this.templatesIDs = this.templateData.map((e) => e._id);
      this.currentPermissionSet["TEMPLATES"] = this.getPermissionsForIds(
        this.templatesIDs
      );
    }
    await this.getCompanyInfo();
    let url_key = this.generateOutputFromUrl(this.routeKey, [
      "key",
      "page",
      "pageSize",
    ]);
    let params = {
      url_key: url_key,
      company_id: this.getActiveWorkspace.company_id,
    };
    await this.$store.dispatch("iFrame/fetchIframeUrlLinks", params);
  },
  data() {
    return {
      link: "",
      dashboard_role: [],
      inputFlag: false,
      generatedLink: "",
      existedLink: "",
      comparedUrlData: {},
      linkName: "",
      toggle: true,
      route: "",
      activeNames: ["1"],
      basicPermissions: ["VIEW", "EDIT", "DELETE", "ADD"],
      EntityTablePermissions: ["VIEW", "EDIT", "DELETE", "SEND NOTIFICATION"],
      EntityViewPermissions: ["EDIT"],
      EntityEditPermissions: [""],
      selectedPermissions: [],
      templatesPermissions: {},
      templateNames: [],
      permissionSet: JSON.parse(JSON.stringify(iFramePermissions)),
      currentPermissionSet: {},
      templatesIDs: {},
      isEditMode: false,
      linkId: "",
    };
  },
  created() {
    this.allTemplatesData = Object.values(this.getOptions(true));
  },
  methods: {
    getTypeChange(newType) {
      this.currentPermissionSet = this.permissionSet[newType];  
    },
    isChecked(templateId, key) {
      return (
        this.templatesPermissions[templateId] &&
        this.templatesPermissions[templateId].includes(key)
      );
    },
    handleChange(templateId, key) {
      if (!this.templatesPermissions[templateId]) {
        this.templatesPermissions[templateId] = [];
      }
      const index = this.templatesPermissions[templateId].indexOf(key);
      if (index !== -1) {
        this.templatesPermissions[templateId].splice(index, 1);
      } else {
        this.templatesPermissions[templateId].push(key);
      }
    },
    async getCompanyInfo() {
      try {
        await this.$store.dispatch(
          "company/fetchCompany",
          this.getActiveWorkspace.company_id
        );
        if (this.getCompanyDetails) {
          this.loading = false;
          this.brandingInfo = { ...this.getCompanyDetails };
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    async openViewConfigPage() {
      let url_key = this.generateOutputFromUrl(this.$route.fullPath, [
        "key",
        "page",
        "pageSize",
      ]);
      const regex = /entity#(\w+)/;
      const match = url_key.match(regex);
      if (match && match[1]) {
        await this.$store.dispatch("entities/fetchEntityById", {
          entity_id: match[1],
        });
      }
      if (this.getEntityDataById) {
        this.templateNames = this.getEntityDataById.templates
          .map((template) => {
            return template.templateInfo ? template.templateInfo.name : null;
          })
          .filter((name) => name !== null);
      }
      let params = {
        url_key: url_key,
        company_id: this.getActiveWorkspace.company_id,
      };
      await this.$store.dispatch("iFrame/fetchIframeUrlLinks", params);
      this.copyModal = true;
      this.generatedLink = "";
      this.link = "";
      this.linkName = "";
      this.selectedPermissions= [];
    },
    getOptions(templateOptions) {
      let data = JSON.parse(JSON.stringify(this.currentPermissionSet));
      if (this.currentPermissionSet?.["TEMPLATES"] && templateOptions) {
        return data["TEMPLATES"];
      }
      delete data["TEMPLATES"];
      return data;
    },
    getTemplateName(templateId) {
      const matchedTemplate = this.templateData.find(
        (e) => e._id === templateId
      );
      return matchedTemplate ? matchedTemplate.templateInfo.name : templateId;
    },
    generateOutputFromUrl(url, excludedKeys = []) {
      let urlParts = url.split("?");
      let path = urlParts[0];
      let queryParams = urlParts.length > 1 ? urlParts[1].split("&") : [];
      let pathSegments = path.split("/");
      let keyValuePairs = {};
      queryParams.forEach((param) => {
        let [key, value] = param.split("=");
        if (!excludedKeys.includes(key)) {
          keyValuePairs[key] = value;
        }
      });
      let output = "";

      pathSegments.forEach((segment, index) => {
        if (index !== 0 && index !== 1) {
          output += `#${segment}`;
        } else {
          output += segment;
        }
      });
      for (const [key, value] of Object.entries(keyValuePairs)) {
        output += `#${key}=${value}`;
      }
      return output;
    },
    generateExistLink(item) {
      let url = appConfig.APP_URL;
      this.existedLink = `${url}/if/${item._id}${item.path}`;
      const tempInput = document.createElement("textarea");
      tempInput.value = this.existedLink;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$message.success("Link copied to clipboard");
    },
    editLink(index) {
      const linkData = this.getIframeUrLinks[index];
      console.log("linkData", linkData);
      this.linkName = linkData.linkName;
      this.link = linkData.url_type;
      this.selectedPermissions =
        linkData.permissions.selectedPermissions.options;
      // this.type = linkData.permissions.type;
    //   const allTemplates = this.getOptions(true);
    //   this.templatesPermissions = {};
    //   for (const templateId in allTemplates) {
    // this.templatesPermissions[templateId] = 
    //   linkData.permissions.templatesPermissions[templateId] || [];
    //   }
      
      this.path = linkData.path;
      this.url_key = linkData.url_key;
      this.generatedLink = ""; 
      this.inputFlag = false; 
      this.isEditMode = true;
      this.linkId = linkData._id;
    },
    async statusChanged(link) {
      let params = {
        id: link._id,
        status: link.status,
      };
      await this.$store.dispatch("iFrame/updateIframeUrlStatus", params);
    },
    onDelete(link) {
      this.$confirm("Are you sure to delete the link?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteURL(link);
      });
    },
    async deleteURL(link) {
      let params = {
        id: link._id,
      };
      await this.$store.dispatch("iFrame/deleteIframeUrl", params);
      this.openViewConfigPage();
    },
    copyLinkToClipboard(refName) {
      this.$refs[refName].focus();
      document.execCommand("copy");
      this.$message.success("Link copied to clipboard");
    },
    async generateLink() {
      if (this.getAuthenticatedSSOToken) {
        if (this.linkName && this.link) {
          let queryParams =
            Object.keys(this.$route.query).length > 0
              ? `?${new URLSearchParams(this.$route.query).toString()}`
              : "";
          let path = this.$route.path + queryParams;
          let url_key = this.generateOutputFromUrl(this.$route.fullPath, [
            "key",
            "page",
            "pageSize",
          ]);
          let payload = {
            url_type: this.link,
            token: this.getAuthenticatedSSOToken,
            path: path,
            url_key: url_key,
            linkName: this.linkName,
            permissions: {
              selectedPermissions: {
                options: this.selectedPermissions,
                type: this.type,
              },
              templatesPermissions: this.templatesPermissions,
            },
          };
          this.inputFlag = true;
          await this.$store.dispatch("iFrame/addIframe", payload);
          let url = appConfig.APP_URL;
          this.generatedLink = `${url}/${this.getIframeData.link}`;
          this.openViewConfigPage();
        } else {
          this.$message.error(
            "Please enter the Link name and choose the link type."
          );
        }
      } else {
        this.$message.error(
          "To generate a link, please log in through Nimble SSO."
        );
      }
    },
    async updateLink() {
      if (this.getAuthenticatedSSOToken) {
        if (this.linkName && this.link) {
          let queryParams =
            Object.keys(this.$route.query).length > 0
              ? `?${new URLSearchParams(this.$route.query).toString()}`
              : "";
          let path = this.$route.path + queryParams;
          let url_key = this.generateOutputFromUrl(this.$route.fullPath, [
            "key",
            "page",
            "pageSize",
          ]);
          let payload = {
            id: this.linkId,
            url_type: this.link,
            token: this.getAuthenticatedSSOToken,
            path: path,
            url_key: url_key,
            linkName: this.linkName,
            permissions: {
              selectedPermissions: {
                options: this.selectedPermissions,
                type: this.type,
              },
              templatesPermissions: this.templatesPermissions,
            },
          };
          await this.$store.dispatch("iFrame/updateIframeUrlData", payload);
          this.openViewConfigPage();
          this.$message.success("Updated Successfully");
        }
      }else {
        this.$message.error(
          "To Update link, please log in through Nimble SSO."
        );
      }
    },
    RemoveData(){
      this.link='';
      this.linkName='';
      this.selectedPermissions=[];

    }
  },
  watch: {
    type(newType) {
      this.getTypeChange(newType);
    },
  },
};
</script>
<style lang="scss">
.el-drawer__wrapper .el-drawer__header {
  background-color: #1b487e;
  border-bottom: none;
  padding-bottom: 0.2em;
  padding-top: 5px;
  color: #ffffff;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  letter-spacing: 0.5px;
}
.el-drawer__header {
  margin-bottom: 1px;
  padding: 20px 20px 0;
}
.checkbox-group-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.el-checkbox {
  margin-bottom: 10px;
}
.checkbox-item {
  display: inline-block;
  width: 50%; 
  box-sizing: border-box; 
  padding-right: 10px; 
  margin-bottom: 10px; 
}
</style>
